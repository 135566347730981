import { axios } from "./../plugins/axios";
/* 获取用户资料 */
export function buy_vip(data) {
  return axios.request({
    method: "POST",
    url: "/api/Member/buy_vip",
    params: {
      ...data
    }
  });
}
export function payment(data) {
  return axios.request({
    method: "POST",
    url: "/api/Pay/pay",
    params: {
      ...data
    }
  });
}
/* 获取vip价格 */
export function vip_price(data) {
  return axios.request({
    method: "POST",
    url: "/api/Member/vip_price",
    params: {
      ...data
    }
  });
}
