<template>
  <div class="container">
    <!-- <van-nav-bar
      title="立即报名"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    /> -->
    <div class="activityinfo">
      <div class="axtiveInfo">
        <div class="user-img">
          <img :src="activityInfo.image" alt="" />
        </div>
        <div class="info">
          <div class="info-title">
            <div class="num">{{ activityInfo.num_limit }}人</div>
            <div class="txt">{{ activityInfo.title }}</div>
          </div>
          <div class="info-time">
            <img src="../assets/img/time.png" alt="" />
            <div>{{ activityInfo.start_time }}</div>
          </div>
          <div class="info-address">
            <img src="../assets/img/address.png" alt="" />
            <div class="van-ellipsis">{{ activityInfo.event_venue }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="money">
      <div class="first">
        <div>原价</div>
        <div>￥{{ activityInfo.entrance_fee }}/人</div>
      </div>
      <div class="second" v-if="activityInfo.grade">
        <div>VIP折扣({{ activityInfo.grade }}折)</div>
        <div>-￥{{ activityInfo.vip_amount }}</div>
      </div>
      <div class="third">
        <div class="actally">实付</div>
        <div class="actallyprice">￥{{ activityInfo.total }}</div>
      </div>
    </div>
    <!-- 用户输入手机号 -->
    <van-form
      validate-first
      @failed="onFailed"
      v-if="activityInfo.has_identity == 0"
      ><!--   -->
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
        v-model="real_name"
        name="姓名"
        placeholder="请输入您的真实姓名"
        label="姓名"
        :rules="[{ required: true, message: '请输入联系人姓名' }]"
      />
      <!-- 通过 validator 进行函数校验 -->
      <van-field
        v-model="identity"
        name="pattern"
        placeholder="请输入您的身份证号"
        label="身份证"
        :rules="[{ pattern, message: '请输入正确的身份证号' }]"
      />
      <!-- 通过 validator 进行异步函数校验 -->
      <van-field
        v-model="mobile"
        name="手机号"
        placeholder="请输入您的手机号"
        label="手机号"
        :rules="[
          { required: true, message: '请输入手机号' },
          {
            validator: checkPhone,
            message: '请输入正确的手机号码'
          }
        ]"
      />
      <van-field
        v-model="wx_id"
        name="微信号"
        placeholder="请输入您的微信号"
        label="微信号"
        :rules="[{ required: true, message: '请输入微信号' }]"
      />
      <!-- <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div> -->
    </van-form>
    <div class="zhanwei"></div>
    <div class="tips">每场活动每人仅可购买一次，一次仅可一人使用</div>
    <div class="payBtn" @click="confirmPay">
      <div class="actally">立即报名</div>
    </div>
  </div>
</template>

<script>
import { signUp, activity_payment } from "@/api/activity";
import { payment } from "@/api/vip";
export default {
  name: "confirmPay",
  components: {},
  data() {
    return {
      id: null,
      rec_id: null,
      activityInfo: {},
      real_name: "",
      identity: "",
      mobile: "",
      wx_id: "",
      pattern:
        /^[1-8][1-7]\d{4}(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/,
      patternTel: /(^1[3|4|5|7|8|9]\d{9}$)|(^09\d{8}$)/,
      patternName: /^[\u4E00-\u9FA5]{2,4}$/
    };
  },
  created() {
    this.id = this.$route.query.id;

    this.getPayInfo();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    onFailed() {
      this.$toast("请填写正确的信息");
    },
    async getPayInfo() {
      const res = await signUp({ a_id: this.id });
      console.log(res, 131);
      if (res.data.code == 200) {
        this.activityInfo = res.data.data;
      } else {
        this.$router.back();
        this.$toast(res.data.message);
      }
    },
    async confirmPay() {
      if (this.activityInfo.has_identity == 0) {
        if (!this.real_name) {
          return this.$toast("请填写姓名");
        }
        if (!this.identity) {
          return this.$toast("请填写身份证");
        }
        if (!this.mobile) {
          return this.$toast("请填写手机号");
        }
        if (!this.wx_id) {
          return this.$toast("请填写微信号");
        }
        const res = await activity_payment({
          a_id: this.id,
          real_name: this.real_name,
          identity: this.identity,
          mobile: this.mobile,
          wx_id: this.wx_id
        });
        let { total, sNo, rec_id } = res.data.data;
        this.rec_id = rec_id;
        if (res.data.code == 200) {
          const res2 = await payment({ total, sNo });
          let obj = {
            appId: res2.data.appid,
            nonceStr: res2.data.nonceStr,
            out_trade_no: res2.data.out_trade_no,
            package: res2.data.package,
            paySign: res2.data.paySign,
            signType: res2.data.signType,
            timeStamp: res2.data.timeStamp
          };
          this.onBridgeReady(obj);
        }
      } else {
        const res = await activity_payment({
          a_id: this.id,
          real_name: this.real_name,
          identity: this.identity,
          mobile: this.mobile,
          wx_id: this.wx_id
        });
        let { total, sNo, rec_id } = res.data.data;
        this.rec_id = rec_id;
        if (res.data.code == 200) {
          const res2 = await payment({ total, sNo });
          let obj = {
            appId: res2.data.appid,
            nonceStr: res2.data.nonceStr,
            out_trade_no: res2.data.out_trade_no,
            package: res2.data.package,
            paySign: res2.data.paySign,
            signType: res2.data.signType,
            timeStamp: res2.data.timeStamp
          };
          this.onBridgeReady(obj);
        }
      }

      /*  */
    },
    onBridgeReady(par) {
      console.log(par, "支付参数");
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke("getBrandWCPayRequest", par, res => {
        console.log(res, "支付回调");
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          // this.vipShow = false;
          this.$router.replace({
            path: "/check",
            query: {
              rec_id: this.rec_id
            }
          });
          console.log("支付成功");
        }
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              // eslint-disable-next-line no-undef
              onBridgeReady,
              false
            );
          } else if (document.attachEvent) {
            // eslint-disable-next-line no-undef
            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
            // eslint-disable-next-line no-undef
            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
          }
        } else {
          // eslint-disable-next-line no-undef
          onBridgeReady();
        }
      });
    },
    checkPhone(val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        this.$toast("手机号码不合法，请重新输入");
        return false;
      }
      return true;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.van-cell {
  display: flex;
  line-height: 100px;
  padding: 0 0.42667rem;
  justify-content: space-between;
  font-size: 33px;
  color: #333333;
}
/deep/.van-field__control {
  text-align: right;
}
/deep/.van-icon-arrow-left::before {
  color: #323233;
}
.container {
  background-color: #f2f2f2;
  min-height: 100vh;
  box-sizing: border-box;
  //  padding-top: 50px;
  .activityinfo {
    width: 100%;

    margin: 0 auto 0;
    background-color: #fff;

    padding: 42px 28px 42px;
    box-sizing: border-box;
    display: flex;

    .axtiveInfo {
      display: flex;
      .user-img {
        width: 174px;
        height: 174px;
        border-radius: 14px;
        margin-right: 14px;
        img {
          width: 174px;
          height: 174px;
          border-radius: 14px;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 12px 0;
        .info-title {
          display: flex;
          .num {
            max-width: 140px;
            height: 33px;
            background: #f45b52;
            border-radius: 3px;
            text-align: center;
            line-height: 37px;
            color: #fff;
            padding: 0 8px;
            margin-right: 8px;
          }
          .txt {
            //height: 31px;
            font-size: 33px;
            width: 380px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: Source Han Sans CN;
            font-weight: 600;
            color: #333333;
          }
        }
        .info-time {
          font-size: 25px;

          font-weight: 400;
          color: #999999;
          display: flex;
          img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
        }
        .info-address {
          font-size: 25px;
          font-weight: 400;
          color: #999999;
          display: flex;
          width: 460px;
          img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .money {
    width: 100%;
    //  height: 208px;
    background-color: #fff;
    margin: 28px auto;
    padding: 0 28px;
    box-sizing: border-box;
    line-height: 100px;
    font-size: 33px;
    .first {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      height: 100px;
    }
    .second {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      height: 100px;
    }
    .third {
      display: flex;
      justify-content: space-between;
      height: 100px;
      .actally {
        color: #000000;
        font-weight: 600;
      }
      .actallyprice {
        font-weight: bold;
        color: #f45b52;
      }
    }
  }
  .zhanwei {
    width: 100%;
    height: 300px;
  }
  .tips {
    width: 100%;
    position: fixed;
    bottom: 135px;
    left: 99px;
    font-size: 26px;
    color: #f45b52;
  }
  .payBtn {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 111px;
    background: #f4a43d;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 28px;
    box-sizing: border-box;
    .actally {
      font-size: 42px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
    }
  }
}
</style>
